.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.mantine-Modal-inner  {
  padding-left: 0!important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: "Graphit";
  src:
    url("../src/assets/fonts/Graphit-Regular.otf") format("opentype");
}
* {
  font-family: 'Graphit', serif!important;
  -webkit-font-smoothing: antialiased;
}

body {
  height: 100vh;
  overflow: hidden;
}

.mantine-Textarea-input {
  background: #e4e9f0!important; 
  border-radius: 16px;
  border: 0
}
.search {
  padding: 0 20px;
  margin-top: 20px;
}
.search .mantine-TextInput-input {
  background: #e4e9f0!important; 
  border-radius: 16px;
  border: 0;
  height: 30px;
  min-height: 30px;
}

.glass {
  background: rgba(255, 255, 255, 0.5);
border-radius: 16px;
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

.mantine-Navbar-root .mantine-Tabs-tabsList {
  flex-direction: column;
}

.mantine-Navbar-root .mantine-Tabs-tab[data-active] {
  background: #d2e8e2;
  color: black;
}